import {DoppeActionLayout} from './doppe-action-layout';
import {DoppeActionListItemClickBehaviour} from './doppe-action-list-item-click-behaviour';
import {DoppeDtoAction, doppeDtoActionGetDefaultSearchListSettingsProps} from './doppe-dto-action';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {DoppeHideableValue, doppeHideableValueCreateHidden} from './doppe-hideable-value';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    BymoPageActionMediaLayout,
    BymoPageActionMediaSize, BymoPageActionMediaSizing,
    BymoPageActionMediaViewLayout,
    BymoPageActionMediaViewSettings
} from '../bymo-pages';
import {HtmlObjectHorizontalPosition, HtmlObjectVerticalPosition} from '@wix/devzai-utils-common';
import {DoppeActionCtaButtonSettings, getDefaultDoppeActionCtaButtonSettings} from './doppe-action-cta-button-settings';

export interface DoppeListActionSettings  {
    itemShowDescription: boolean;
    itemShowImage: boolean;
    itemShowTitle: boolean;
    itemShowTextOnImage: boolean;
    layout: DoppeActionLayout;
    latestNonPremiumLayout?: DoppeActionLayout;
    showButton: boolean;
    buttonText: string;
    buttonUrl: string;
    listSearchSettings: DoppeDtoAction.ListSearchSettings;
}


export namespace DoppeListActionSettings {


    export interface WithExternalDataSourceSupport<T extends {}> {
        externalDataSource: null | T;
        externalDataSourceItemsCount: number;
    }

    export interface WithCtaButton  extends DoppeActionCtaButtonSettings {

    }

    export interface WithMainDetails  {
        mainImage: DoppeHideableValue<WixMediaResource | null>;
        mainMediaViewSettings: BymoPageActionMediaViewSettings;
        mainMediaViewLayout: BymoPageActionMediaViewLayout;
        mainTitle: DoppeHideableValue<string>;
    }

    export interface WithItemClickBehaviour  {
        itemClickBehaviour: DoppeActionListItemClickBehaviour;
    }
}


export function getDefaultDoppeActionCtaButtonSettingsFromListAction(actionSettings: Partial<DoppeListActionSettings & DoppeListActionSettings.WithCtaButton>, pageContentLocales?: DoppeAppPageContentLocales) {
    return getDefaultDoppeActionCtaButtonSettings({
        buttonText: actionSettings.buttonText ?? '',
        url: actionSettings.buttonUrl ?? '',
        showButton: actionSettings.showButton !== undefined ? actionSettings.showButton : false,
        pageContentLocales: pageContentLocales,
    });
}


export function getDefaultDoppeListActionSettings(pageContentLocales?: DoppeAppPageContentLocales) {
    return {
        itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
        layout: DoppeActionLayout.Column,
        showButton: false,
        buttonText: pageContentLocales ? pageContentLocales["lists.actionSettings.buttonText"] : '',
        buttonUrl: 'https://www.website.com',

        itemShowDescription: true,
        itemShowTitle: true,
        itemShowImage: true,
        itemShowTextOnImage: false,
        listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
        ...getDefaultDoppeActionCtaButtonSettings({
            buttonText: pageContentLocales ? pageContentLocales["lists.actionSettings.buttonText"] : '',
            url: 'https://www.website.com',
            showButton: false,
        }),
    }
}

export function doppeListActionGetMainDetailsDefaultSettings (
    options: {
        defaultTitle?: string;
    } = {}
) {
    return {
        mainImage: doppeHideableValueCreateHidden(null),
        mainTitle: doppeHideableValueCreateHidden(options.defaultTitle ?? 'Title'),
        mainMediaViewSettings: {
            mediaLayout: BymoPageActionMediaLayout.Cropped,
            mediaSizing: BymoPageActionMediaSizing.Custom,
            size: BymoPageActionMediaSize.Ratio3By2,
            alignment: {
                horizontal: HtmlObjectHorizontalPosition.Center,
                vertical: HtmlObjectVerticalPosition.Center
            }
        },
        mainMediaViewLayout: BymoPageActionMediaViewLayout.FullWidth,
    } satisfies DoppeListActionSettings.WithMainDetails;
}


import {Values} from '@wix/devzai-utils-common';

export const DoppeAutomationTriggers = {
    ContactAdded: 'hopp-contact_added',
    SubscribeFormSubmitted: 'hopp-subscribe_form_submitted',
    ContactFormSubmitted: 'hopp-contact_form_submitted',
    DonatePaymentDone: 'hopp-donation_payment_done',
    PaidMessagePaymentDone: 'hopp-paid_message_payment_done',
    PaidRequestPaymentDone: 'hopp-paid_request_payment_done',
} as const;

export type DoppeAutomationTriggers = Values<typeof DoppeAutomationTriggers>;

export type DoppeAutomationTriggerData =
    | DoppeAutomationTriggerData.ContactAdded
    | DoppeAutomationTriggerData.ContactFormSubmitted
    | DoppeAutomationTriggerData.SubscribeFormSubmitted
    | DoppeAutomationTriggerData.DonatePaymentDone
    | DoppeAutomationTriggerData.PaidMessagePaymentDone
    | DoppeAutomationTriggerData.PaidRequestPaymentDone;

export namespace DoppeAutomationTriggerData {

    type BaseType<T extends DoppeAutomationTriggers> = {
        triggerKey: T;
        actionId: string;
        contactList?: string;
        contactId: string;
        isNewContact: boolean;
    }

    export type ContactAdded = Omit<BaseType<typeof DoppeAutomationTriggers.ContactAdded>, 'isNewContact'> & {
    }

    export type ContactFormSubmitted = BaseType<typeof DoppeAutomationTriggers.ContactFormSubmitted> & {
        message?: string;
    }

    export type SubscribeFormSubmitted = BaseType<typeof DoppeAutomationTriggers.SubscribeFormSubmitted> & {
    }

    export type DonatePaymentDone = BaseType<typeof DoppeAutomationTriggers.DonatePaymentDone> & {
        message?: string;
    }

    export type PaidMessagePaymentDone = BaseType<typeof DoppeAutomationTriggers.PaidMessagePaymentDone> & {
        message?: string;
    }

    export type PaidRequestPaymentDone = BaseType<typeof DoppeAutomationTriggers.PaidRequestPaymentDone> & {
        message?: string;
    }
}
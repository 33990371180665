import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {
    convertDoppeWixBlogPostToBlogPostSettings,
    DoppeActionTypeBlogPost,
    populateBlogPostActionSettingsDefaultTexts
} from '../doppe-action-type-blog-post/doppe-action-type-blog-post';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    DoppeBlogPostsExternalDataSource,
    doppeBlogPostsExternalDataSourceResolveBusinessEntityReferences,
    doppeExternalBlogPostProviderResolveBusinessEntityReference,
    doppeHideableValueIsHidden,
    doppeHideableValueModifyValue,
    doppeListActionGetMainDetailsDefaultSettings,
    DoppeListActionSettings,
    getDefaultDoppeActionCtaButtonSettingsFromListAction
} from '../../client-server-common';
import {DoppeDtoBlogPost} from '../../client-server-common/types/doppe-dto-blog-post';
import {doppeHideableValueCreateHidden} from '../../client-server-common/types/doppe-hideable-value';
import {
    doppeDtoActionGetDefaultSearchListSettingsProps,
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';


export namespace DoppeActionTypeArticlesList {

    export interface ArticleSettings extends DoppeActionTypeBlogPost.ActionSettings {
        id: string;
        blogPostTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeBlogPostsExternalDataSource>
    {
        articles: ArticleSettings[];
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowBlogPostDate: boolean;
        previewShowBlogWebsite: boolean;
        previewBlogPostButtonText: string;
    }
}


export const doppeActionTypeArticlesList = doppeActionTypeDefineMetadata<DoppeActionTypeArticlesList.ActionSettings>({
    id: 'io.bymo.action/articles-list',
    resolveActionSettings: action => {

        const {
            articles = [],
            buttonText,
            ...restSettings
        } = action.settings;

        return {
            articles: articles.map(article => {
                return {
                    blogPostProvider: null,
                    blogPostProviderId: null,
                    ...article as PartiallyOptional<DoppeActionTypeArticlesList.ArticleSettings, 'blogPostProvider' | 'blogPostProviderId'>
                }
            }) ?? [],
            layout: DoppeActionLayout.Column,
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
            showButton: false,
            buttonText: 'Visit My Blog',
            buttonUrl: 'https://www.website.com',
            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowTextOnImage: false,
            previewShowBlogPostDate: true,
            previewShowBlogWebsite: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewBlogPostButtonText: 'Read Now',
            externalDataSource: null,
            externalDataSourceItemsCount: 4,
            listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
            ...getDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.articles.forEach(article => {
                article.blogPostLink = '';
            });
        }, options);
    },
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.articles.map(article => {
            return article.blogPostImages ?? []
        }));
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.articles[0]?.blogPostImages?.[0] ?? null;
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeBlogPostsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.articles, (article, skip) => {
                return article.blogPostProvider !== null && article.blogPostProviderId !== null ?
                    doppeExternalBlogPostProviderResolveBusinessEntityReference(
                        article.blogPostProvider,
                        article.blogPostProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : actionSettings.articles.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.articles.map(article => {
            return {
                title: article.blogPostTitle,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: article.description,
                image: article.blogPostImages?.[0] ?? null,
                itemId: article.id,
                ribbon: doppeHideableValueCreateHidden(''),
                url: article.blogPostLink
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const article of actionSettings.articles) {
                if (article.blogPostImages) {
                    article.blogPostImages = article.blogPostImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
});

export function populateBlogPostsActionPostSettingsDefaultTexts(options: {
    blogPost: DoppeActionTypeArticlesList.ArticleSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}) : DoppeActionTypeArticlesList.ArticleSettings {

    const {
        pageContentLocales,
        blogPost,
    } = options;

    const normalizedBlogPost = populateBlogPostActionSettingsDefaultTexts({
        pageContentLocales: pageContentLocales,
        blogPost: blogPost
    })

    ;

    return {
        ...blogPost,
        ...normalizedBlogPost,
        blogPostTitle: doppeHideableValueIsHidden(normalizedBlogPost.blogPostTitle) ? '' : normalizedBlogPost.blogPostTitle,
        blogPostButtonText: doppeHideableValueModifyValue(normalizedBlogPost.blogPostButtonText, (value) => {
            return value ?? pageContentLocales["blogPost.actionSettings.buttonText"]
        }),
    }
}

export function convertDoppeWixBlogPostToBlogPostsSettings(blogPost: DoppeDtoBlogPost): DoppeActionTypeArticlesList.ArticleSettings {

    const blogPostSettings = convertDoppeWixBlogPostToBlogPostSettings(blogPost);

    return {
        ...blogPostSettings,
        blogPostTitle: doppeHideableValueIsHidden(blogPostSettings.blogPostTitle) ? '' : blogPostSettings.blogPostTitle,
        blogPostButtonText: doppeHideableValueCreateHidden(''),
        id: blogPost.id,
    }

}
import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    doppeExternalEventProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueIsHidden,
    doppeHideableValueModifyValue, doppeListActionGetMainDetailsDefaultSettings,
    DoppeListActionSettings, getDefaultDoppeActionCtaButtonSettingsFromListAction
} from '../../client-server-common';
import {
    convertDoppeWixEventToEventSettings,
    DoppeActionTypeEvent,
    populateEventActionSettingsDefaultTexts
} from '../doppe-action-type-event/doppe-action-type-event';
import {DoppeDtoEvent} from '../../client-server-common/types/doppe-dto-event';
import {
    DoppeEventsExternalDataSource,
    doppeEventsExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-events-external-data-source';
import {
    doppeDtoActionGetDefaultSearchListSettingsProps,
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';


export namespace DoppeActionTypeEvents {

    export interface EventSettings extends DoppeActionTypeEvent.ActionSettings {
        id: string;
        eventTitle: string;
    }

    export interface ActionSettings extends DoppeListActionSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeEventsExternalDataSource> {
        events: EventSettings[];
        itemShowDate: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowCoupon: boolean;
        previewShowPrice: boolean;
        previewEventButtonText: string;
    }
}


export const doppeActionTypeEvents = doppeActionTypeDefineMetadata<DoppeActionTypeEvents.ActionSettings>({
    id: 'io.bymo.action/events',
    resolveActionSettings: action => {

        const {
            events = [],
            ...restSettings
        } = action.settings;

        return {
            events: events.map(event => {
                return {
                    eventProviderId: null,
                    eventProvider: null,
                    ribbon: doppeHideableValueCreateHidden(''),
                    ...event as PartiallyOptional<DoppeActionTypeEvents.EventSettings, 'eventProvider' | 'eventProviderId' | 'ribbon'>
                }
            }),
            layout: DoppeActionLayout.Grid,
            showButton: false,
            buttonText: '',
            buttonUrl: 'https://www.website.com',
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItem,
            externalDataSource: null,
            externalDataSourceItemsCount: 4,
            itemShowDescription: true,
            itemShowTitle: true,
            itemShowImage: true,
            itemShowTextOnImage: false,
            itemShowDate: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewEventButtonText: '',
            listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
            ...getDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.events.forEach(item => {
                item.eventLink = '';
            });
        }, options);
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : actionSettings.events.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.events.map(item => {
            return {
                title: item.eventTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.description,
                image: item.eventImages?.[0] ?? null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.eventLink
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.events.map(event => {
            return event.eventImages ?? []
        }));
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.events[0]?.eventImages?.[0] ?? null;
    },
    resolveMainLink: actionSettings => {
        return actionSettings.showButton ? actionSettings.buttonUrl : null;
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeEventsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.events, (event, skip) => {
                return event.eventProvider !== null && event.eventProviderId !== null ?
                    doppeExternalEventProviderResolveBusinessEntityReference(
                        event.eventProvider,
                        event.eventProviderId
                    ) : skip
            });
        }
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const event of actionSettings.events) {
                if (event.eventImages) {
                    event.eventImages = event.eventImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
});

export function populateEventsActionEventSettingsDefaultTexts(options: {
    event: DoppeActionTypeEvents.EventSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}): DoppeActionTypeEvents.EventSettings {

    const {
        pageContentLocales,
        event,
    } = options;

    const normalizedContent = populateEventActionSettingsDefaultTexts({
        pageContentLocales: pageContentLocales,
        event: event
    });

    return {
        ...event,
        ...normalizedContent,
        eventTitle: doppeHideableValueIsHidden(normalizedContent.eventTitle) ? '' : normalizedContent.eventTitle,
        eventButtonText: doppeHideableValueModifyValue(normalizedContent.eventButtonText, (value) => {
            return value ?? pageContentLocales['event.actionSettings.buttonText']
        }),
    }
}

export function convertDoppeWixEventToEventsSettings(
    event: DoppeDtoEvent,
    settings?: {
        eventButtonText?: DoppeHideableValue<string>;
    }
): DoppeActionTypeEvents.EventSettings {
    return {
        ...convertDoppeWixEventToEventSettings(event),
        id: event.id,
        couponCode: doppeHideableValueCreateHidden(''),
        ribbon: doppeHideableValueCreateHidden(''),
        eventButtonText: settings?.eventButtonText ?? doppeHideableValueCreateHidden(''),
    }
}
import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, asArray, immerProduce} from '@wix/devzai-utils-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    DoppeHideableValue, doppeHideableValueCreateHidden, doppeListActionGetMainDetailsDefaultSettings,
    DoppeListActionSettings, getDefaultDoppeActionCtaButtonSettingsFromListAction
} from '../../client-server-common';
import {DoppeActionTypeIframe} from '../doppe-action-type-iframe/doppe-action-type-iframe';
import {RichTextContent} from '@wix/devzai-utils-react';
import {doppeDtoActionGetDefaultSearchListSettingsProps} from '../../client-server-common/types/doppe-dto-action';

export namespace DoppeActionTypeEmbedPageList {

    export interface EmbedPageSettings extends  DoppeActionTypeIframe.ActionSettings {
        id: string;
        title: string;
        image: WixMediaResource[] | null;
        description: DoppeHideableValue<RichTextContent>;
    }

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour
    {
        links: EmbedPageSettings[];
    }
}


export const doppeActionTypeEmbedPageList = doppeActionTypeDefineMetadata<DoppeActionTypeEmbedPageList.ActionSettings>({
    id: 'io.bymo.action/embed-page-list',
    resolveActionSettings: action => {

        const {
            links = [],
            ...restSettings
        } = action.settings;

        return {
            links: links,
            itemClickBehaviour: DoppeActionListItemClickBehaviour.OpenLink,
            layout: DoppeActionLayout.Column,
            showButton: false,
            buttonText: 'See More Links',
            buttonUrl: 'https://www.website.com',
            previewLinkButtonText: 'Continue',
            itemShowTitle: true,
            itemShowImage: true,
            itemShowTextOnImage: false,
            itemShowDescription: true,
            listSearchSettings: doppeDtoActionGetDefaultSearchListSettingsProps(),
            ...getDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: false,
    notSupportsRenderingPreviewItem: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(actionSettings.links.map(link => {
            return link.image ? asArray(link.image) : []
        }));
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.links[0]?.image ? actionSettings.links[0]?.image[0] : null;
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.links.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.links.map(item => {
            return {
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.image?.[0] ?? null,
                itemId: item.id,
                ribbon: doppeHideableValueCreateHidden(''),
                url: null
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const link of actionSettings.links) {
                if (link.image) {
                    link.image = asArray(link.image).map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }
        })
    },
    hasNoLockInnerCTA: true,
});

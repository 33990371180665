import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeCheckoutActionSettings,
    getDefaultDoppeCheckoutActionSettings,
    prepareCheckoutSettingsForViewer,
} from '../../client-server-common/types/doppe-checkout-action-settings';
import {arrayFlatten, asArray, immerProduce, iterableMapToArray, PartiallyOptional} from '@wix/devzai-utils-common';
import {DoppeActionTypeRequest} from '../doppe-action-type-request/doppe-action-type-request';
import {
    DoppeAutomationTriggers,
    doppePremiumFeatureIsDisabled,
    DoppePremiumFeatures,
    DoppeListActionSettings,
    getDefaultDoppeListActionSettings,
    doppeListActionGetMainDetailsDefaultSettings,
} from '../../client-server-common';


export namespace DoppeActionTypeRequests {

    export interface ActionSettings extends
        DoppeListActionSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeCheckoutActionSettings {
        requestOptions: DoppeActionTypeRequest.RequestOption[];
        requestsInstructions: DoppeHideableValue<string>;
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewCheckoutButtonText: string;
    }

}


export const doppeActionTypeRequests = doppeActionTypeDefineMetadata<DoppeActionTypeRequests.ActionSettings>({
    id: 'co.hopp.action/request',
    resolveActionSettings: action => {
        const {
            requestOptions = [],
            previewCheckoutButtonText = '',
            ...restSettings
        } = action.settings;


        return {
            ...getDefaultDoppeListActionSettings(),
            ...getDefaultDoppeCheckoutActionSettings(
                {
                    withEmailNotification: true,
                }
            ),
            requestsInstructions: doppeHideableValueCreateHidden(''),
            itemShowPrice: true,
            previewShowTitle: true,
            previewShowDescription: true,
            previewCheckoutButtonText: previewCheckoutButtonText,
            requestOptions: requestOptions.map(requestOption => {
                return {
                    requestsInstructions: doppeHideableValueCreateHidden(''),
                    ribbon: doppeHideableValueCreateHidden(''),
                    showNotesInput: true,
                    previewCheckoutButtonText: doppeHideableValueCreateHidden(previewCheckoutButtonText),
                    ...requestOption as PartiallyOptional<DoppeActionTypeRequest.RequestOption, 'requestsInstructions' | 'showNotesInput' | 'ribbon' | 'previewCheckoutButtonText'>
                }
            }),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...restSettings
        }
    },
    prepareForViewer: actionSettings => {
        prepareCheckoutSettingsForViewer(actionSettings);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: true,
    getSupportedAutomationTrigger: () => DoppeAutomationTriggers.PaidRequestPaymentDone,
    isFormAction: false,
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeatures.creators_monetization_actions)
    },
    isListItemLinkOpener: false,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayFlatten(iterableMapToArray(actionSettings.requestOptions, (requestOption, skip) => {
            return requestOption.images ? asArray(requestOption.images) : skip
        }));
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.requestOptions[0].images ? asArray(actionSettings.requestOptions[0].images)[0] : null
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.requestOptions.length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.requestOptions.map(item => {
            return {
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.images ? asArray(item.images)[0] : null,
                itemId: item.id,
                ribbon: doppeHideableValueCreateHidden(''),
                url: null
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.requestOptions) {
                if (item.images) {
                    const images = asArray(item.images);
                    item.images = images.map(image => {
                        return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                    });
                }
            }
        })
    },
});

import {
    AutoValue,
    autoValueCreate,
    autoValueIsAutoValue,
    evaluateFunction,
    stringTrimPrefix,
    validateIsEmail,
    validateIsUrl,
    Values
} from '@wix/devzai-utils-common';
import {DoppeHideableValue, doppeHideableValueGetVisibleValue} from './doppe-hideable-value';
import {Button} from '@wix/devzai-utils-react';
import {DoppeActionGoalType} from '../doppe-action-types/doppe-action-type';

export const DoppeActionCTAType = {
    Link: 'Link',
    Whatsapp: 'Whatsapp',
    Call: 'Call',
    Navigate: 'Navigate',
    Email: 'Email',
    FacebookMessage: 'FacebookMessage',
} as const;

export type DoppeActionCTAType = Values<typeof DoppeActionCTAType>;

export const DoppeActionCTATypeLinkAutoSource = {
    SameAsSourceURL: 'SameAsSourceURL',
} as const;

export type DoppeActionCTATypeLinkAutoSource = Values<typeof DoppeActionCTATypeLinkAutoSource>;

export type DoppeActionCTATypeLinkSource = AutoValue<DoppeActionCTATypeLinkAutoSource> | string;

export type DoppeActionCTAButton = {
    settings: DoppeActionCTAButtonSettings;
    text: DoppeHideableValue<string>;
    showButton: boolean;
}

export type DoppeActionCTAButtonSettings =
    | DoppeActionCTAButtonSettings.Link
    | DoppeActionCTAButtonSettings.Whatsapp
    | DoppeActionCTAButtonSettings.Call
    | DoppeActionCTAButtonSettings.Navigate
    | DoppeActionCTAButtonSettings.Email
    | DoppeActionCTAButtonSettings.FacebookMessage;

export namespace DoppeActionCTAButtonSettings {

    type BaseType<T extends DoppeActionCTAType> = {
        type: T;
    }

    export type Link = BaseType<typeof DoppeActionCTAType.Link> & {
        url: DoppeActionCTATypeLinkSource;
    };

    export type Whatsapp = BaseType<typeof DoppeActionCTAType.Whatsapp> & {
        phone: string;
        customMessage: DoppeHideableValue<string>;
    };

    export type Call = BaseType<typeof DoppeActionCTAType.Call> & {
        phone: string;
    };

    export type Email = BaseType<typeof DoppeActionCTAType.Email> & {
        email: string;
        customSubject: DoppeHideableValue<string>;
        customMessage: DoppeHideableValue<string>;
    };

    export type Navigate = BaseType<typeof DoppeActionCTAType.Navigate> & {
        address: string;
    };

    export type FacebookMessage = BaseType<typeof DoppeActionCTAType.FacebookMessage> & {
        userName: string;
        customMessage: DoppeHideableValue<string>
    };

}


export function doppeActionCTAButtonIsVisible(ctaButton: DoppeActionCTAButton) {
    return ctaButton.showButton;
}

export function doppeActionCTAButtonCreateLinkSameAsSource(options: {
    text: string,
    hidden?: boolean
}) {

    const {
        text,
        hidden = false
    } = options;

    return {
        settings: {
            type: DoppeActionCTAType.Link,
            url: autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL),
        },
        text: text,
        showButton: !hidden
    }
}

export function doppeActionCtaButtonTypeGetLinkSettings(ctaButton: DoppeActionCTAButton, customURL: string) {

    const {
        type,
    } = ctaButton.settings;

    const xxx = evaluateFunction(() => {
        switch (type) {
            default: {
                return {
                    href: doppeActionCtaButtonTypeResolveURL(ctaButton.settings, customURL),
                    target: '_blank',
                    rel: 'noopener',
                    tagName: 'a'
                }
            }
        }
    })

    return xxx as {
        href: string;
        target: string;
        rel: string;
        tagName: Button.Props['tagName'];
    }
}

export function doppeActionCtaButtonTypeGetGoalType(type: DoppeActionCTAType) {

    switch (type) {
        case DoppeActionCTAType.Whatsapp: {
            return DoppeActionGoalType.WhatsappClick;
        }
        case DoppeActionCTAType.Call: {
            return DoppeActionGoalType.CallButtonClick;
        }
        case DoppeActionCTAType.Link: {
            return DoppeActionGoalType.Click;
        }
        case DoppeActionCTAType.Email: {
            return DoppeActionGoalType.EmailButtonClick;
        }
        case DoppeActionCTAType.Navigate: {
            return DoppeActionGoalType.NavigateButtonClick;
        }
        case DoppeActionCTAType.FacebookMessage: {
            return DoppeActionGoalType.FacebookMessageButtonClick;
        }

    }
}

export function doppeActionCtaButtonTypeGetDefaultSettings(type: DoppeActionCTAType, sourceURL?: string) {

    switch (type) {
        case DoppeActionCTAType.Whatsapp: {
            return {
                type: DoppeActionCTAType.Whatsapp,
                phone: '',
                customMessage: '',
            };
        }
        case DoppeActionCTAType.Call: {
            return {
                type: DoppeActionCTAType.Call,
                phone: '',
            };
        }
        case DoppeActionCTAType.Link:
            return {
                type: DoppeActionCTAType.Link,
                url: sourceURL ? autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL) : '',
            };
        case DoppeActionCTAType.Email: {
            return {
                type: DoppeActionCTAType.Email,
                email: '',
                customMessage: '',
                customSubject: ''
            };
        }
        case DoppeActionCTAType.Navigate: {
            return {
                type: DoppeActionCTAType.Navigate,
                address: '',
            };
        }
        case DoppeActionCTAType.FacebookMessage: {
            return {
                type: DoppeActionCTAType.FacebookMessage,
                userName: '',
                customMessage: '',
            };
        }
    }
}

export function doppeActionCtaButtonTypeResolveURL(ctaSettings: DoppeActionCTAButtonSettings, sourceURL?: string) {

    switch (ctaSettings.type) {
        case DoppeActionCTAType.Call: {
            return `tel:${ctaSettings.phone}`;
        }
        case DoppeActionCTAType.Email: {

            if (!validateIsEmail(ctaSettings.email)) {
                return undefined;
            }

            return `mailto:${ctaSettings.email}?subject=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customSubject, ''))}&body=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customMessage, ''))}`
        }
        case DoppeActionCTAType.Link: {

            const url = autoValueIsAutoValue(ctaSettings.url) ? sourceURL ?? '' : ctaSettings.url;

            if (validateIsUrl(url, {
                protocols: ['http', 'https'],
                require_protocol: true
            })) {
                return url;
            } else {
                return '';
            }

        }
        case DoppeActionCTAType.Whatsapp: {
            return `https://api.whatsapp.com/send?phone=${stringTrimPrefix(ctaSettings.phone, '+')}&text=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customMessage, ''))}`;
        }
        case DoppeActionCTAType.Navigate: {
            if(!ctaSettings.address) {
                return undefined;
            }

            return `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(ctaSettings.address)}`
        }
        case DoppeActionCTAType.FacebookMessage: {
            return ctaSettings.userName !== undefined ?
                `https://www.m.me/${ctaSettings.userName}&text=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customMessage, ''))}` :
                undefined;
        }
    }
}


import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {DoppeActionCTAButton, DoppeActionCTAType, DoppeActionCTATypeLinkAutoSource} from './doppe-action-cta-button';
import {autoValueCreate} from '@wix/devzai-utils-common';

export interface DoppeActionCtaButtonSettings {
    ctaButton: DoppeActionCTAButton;
}




export function getDefaultDoppeActionCtaButtonSettings(options: {
    pageContentLocales?: DoppeAppPageContentLocales,
    buttonText: string,
    url?: string,
    showButton: boolean
}): DoppeActionCtaButtonSettings {

    const {
        pageContentLocales,
        buttonText,
        url,
        showButton,
    } = options;


    return {
        ctaButton: {
            settings: {
                type: DoppeActionCTAType.Link,
                url: url ?? autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL),
            },
            text: buttonText ? buttonText : pageContentLocales ? pageContentLocales["ctaButton.actionSettings.buttonText"] : '',
            showButton: showButton,
        }
    }
}


